/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const PlayIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M5 4.6L17.8 12 5 19.4V4.6M3.7 2.5v19.1L20.3 12 3.7 2.5z" />
	</svg>
))
PlayIconSvg.displayName = 'PlayIconSvg'

const PlayIcon = forwardRef((props, ref) => <Icon component={PlayIconSvg} ref={ref} {...props} />)
PlayIcon.displayName = 'PlayIcon'

PlayIcon.defaultProps = {
	...Icon.defaultProps,
}
PlayIcon.propTypes = {
	...Icon.propTypes,
}

export default PlayIcon
export { PlayIconSvg }
