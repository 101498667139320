import slideConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/slide/config-aggregator'
import sliderConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/slider/config-aggregator'

const configAggregator = (node) => {
	return {
		...sliderConfigAggregator(node),
		childrenProps: node.children.map((child) => slideConfigAggregator(child, child.attribs)),
	}
}

export default configAggregator
